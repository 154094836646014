<template>
  <div class="panorama-view h-100 p-20">
    <h3 class="b-b-1 m-b-10 title">{{ this.view.name || '研学全景图' }}</h3>
    <Button type="error" size="small" class="extra" to="/config/dataManage/panorama">返回</Button>
    <div ref="map" id="container" class="map-warp">
      <div class="map_tool">
        <ButtonGroup v-if="view.qjtBtn && view.qjtBtn.length">
          <Poptip word-wrap width="800" placement="bottom-start" :content="item.btnText" v-for="(item, index) in view.qjtBtn" :key="index">
            <Button type="info" size="small">{{ item.btnName }}</Button>
          </Poptip>
        </ButtonGroup>
      </div>
    </div>
  </div>
</template>

<script>
import { getPanoramaById } from '@/libs/api/panorama'
import * as Three from 'three'
// 导入轨道控制器
import {
  OrbitControls
} from 'three/examples/jsm/controls/OrbitControls'
// import {RGBELoader} from 'three/examples/jsm/loaders/RGBELoader'
let scene = null,
camera=null,
renderer=null,
mesh=null
export default {
  data() {
    return {
      view: {},
      current: {},
      timer: null,
      flag: false
    }
  },
  methods: {
    getIntro(item) {
      if(item) {
        this.current = item
      } else {
        this.current = {}
      }
    },
    // sortArr(data) {
    //   /**
    //    * 'mobile/mobile_r.jpg', //r=right 右
		// 			'mobile/mobile_l.jpg', //l=left 左
		// 			'mobile/mobile_u.jpg', //u=up 上
		// 			'mobile/mobile_d.jpg', //d=down 下
		// 			'mobile/mobile_f.jpg', //f=front 前
		// 			'mobile/mobile_b.jpg', //b=back 后
    //    */
    //   let arr = []
    //   data.map(item => {
    //     let url = item.split('.')[0]
    //     let tag = url[url.length - 1]
    //     console.log(data, url, tag)
    //     switch(tag) {
    //       case 'r':
    //         arr[0] = item;
    //         break;
    //       case 'l':
    //         arr[1] = item;
    //         break;
    //       case 'u':
    //         arr[2] = item;
    //         break;
    //       case 'd':
    //         arr[3] = item;
    //         break;
    //       case 'f':
    //         arr[4] = item;
    //         break;
    //       case 'b':
    //         arr[5] = item;
    //         break;
    //       default:
    //         break;
    //     }
    //   })
    //   return arr
    // },
    init(){
      let container = document.getElementById('container');

      // 添加相机
      camera = new Three.PerspectiveCamera(70, container.clientWidth/container.clientHeight, 0.01, 1000);
      // 相机的位置
      camera.position.z = 0.1

      // 场景
      scene = new Three.Scene()

      

      // type: true,为立方体全景，false为球形
      if(this.view.type) {
        // let urls = [
        //   '/static/images/panorama/北海港口r.jpg',
        //   '/static/images/panorama/北海港口l.jpg',
        //   '/static/images/panorama/北海港口u.jpg',
        //   '/static/images/panorama/北海港口d.jpg',
        //   '/static/images/panorama/北海港口f.jpg',
        //   '/static/images/panorama/北海港口b.jpg',
        // ]
        let urls = this.view.urlList
        let cubeTexture = new Three.CubeTextureLoader().load(urls);
				scene.background = cubeTexture;

      } else {

        // 创建球
        let geometry=new Three.SphereBufferGeometry(5,32,32)
        let a=new Three.TextureLoader().load(this.view.url)
        let material = new Three.MeshBasicMaterial({map: a });
        mesh = new Three.Mesh(geometry, material);
        mesh.geometry.scale(1, 1, -1);
        scene.add(mesh);

      }
        

      
      // 初始化渲染器
      renderer = new Three.WebGLRenderer({antialias:true});
      // 渲染器的大小
      renderer.setSize(container.clientWidth,container.clientHeight);
      // 将渲染器添加到页面
      container.appendChild(renderer.domElement);



      // 创建轨道控制器 相机围绕模型看到的角度
      const OrbitControl = new OrbitControls(camera, renderer.domElement)
      // 设置轨道自然
      OrbitControl.enableDamping = true
      // 设置惯性
      OrbitControl.update()


    },
    animate(){
      // 浏览器自动渲染下一帧
      if(this.flag) {
        this.timer = window.requestAnimationFrame(this.animate);
      }

      
      // mesh.rotation.x += 0.001;
      // 沿Y轴顺时针旋转
      // mesh.rotation.y += 0.0005;
        
      camera.rotation.y -= 0.0005;
     
      // 渲染到页面
      renderer.render(scene,camera); 
    },
    stop() {
      window.cancelAnimationFrame(this.animate)
      this.timer = null
      this.flag = false
    }
  },
  
  async mounted() {
    this.flag = true
    const { id } = this.$route.query
    if(id) {
      const { code, data } = await getPanoramaById({ id })
      if(code == "HA0200") {
        this.view = data
        this.init()
        this.animate()
      }
    }


  },
  beforeDestroy() {
    window.cancelAnimationFrame(this.animate)
    this.timer = null
    this.flag = false
  }
  
}
</script>

<style lang="less" scoped>
.panorama-view {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  .title {
    height: 30px;
  }
  .extra {
    position: absolute;
    right: 30px;
    top: 20px;
  }
  .map-warp {
    height: calc(100% - 40px);
    position: relative;
    .map_tool {
      position: absolute;
      top: 10px;
      left: 10px;
    }
  }
}
</style>